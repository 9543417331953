/* スマホ用のCSSはメディアクエリの外に記述する */
.frame {
  text-align: center;
  margin: 0px 8%;
}

@media screen and (min-width: 651px) {
  /* 481px以上に適用されるCSS（タブレット用） */
  .frame {
    margin: 0px 25%;
  }
}
@media screen and (min-width: 960px) {
  /* 960px以上に適用されるCSS（PC用） */
  .frame {
    margin: 0px 33%;
  }
}
