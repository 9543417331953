/* 矢印上方向 */

.square_triangle_arrow_top {
  position: relative;
  margin-top: 75px;
}

.square_triangle_arrow_top::after {
  content: "";
  position: absolute;
  top: -50px;
  right: 43%;
  width: 0;
  height: 0;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
  border-left: 45px solid #4682b4;
  transform: rotate(90deg);
}

/* 矢印右方向 */
.square_triangle_arrow_right {
  position: relative;
  padding-right: 25px;
  margin-top: 25px;
}

.square_triangle_arrow_right::after {
  content: "";
  position: absolute;
  top: 45%;
  right: 5px;
  width: 0;
  height: 0;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
  border-left: 45px solid #4682b4;
  transform: rotate(180deg);
}

/* 矢印下方向 */

.square_triangle_arrow_bottom {
  position: relative;
  margin-bottom: 75px;
}

.square_triangle_arrow_bottom::after {
  content: "";
  position: absolute;
  bottom: -50px;
  left: 43%;
  width: 0;
  height: 0;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
  border-left: 45px solid #4682b4;
  transform: rotate(270deg);
}

/* 矢印左方向 */
.square_triangle_arrow_left {
  position: relative;
  padding-left: 25px;
  margin-top: 25px;
}

.square_triangle_arrow_left::after {
  content: "";
  position: absolute;
  top: 45%;
  left: 5px;
  width: 0;
  height: 0;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
  border-left: 45px solid #4682b4;
  transform: rotate(360deg);
}

/* 矢印右上方向 */

.square_triangle_arrow_right_top {
  position: relative;
  margin-top: 80px;
  padding-right: 25px;
}

.square_triangle_arrow_right_top::after {
  content: "";
  position: absolute;
  top: -50px;
  right: 16px;
  width: 0;
  height: 0;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
  border-left: 45px solid #4682b4;
  transform: rotate(135deg);
}

/* 矢印左上方向 */

.square_triangle_arrow_left_top {
  position: relative;
  margin-top: 80px;
  padding-left: 25px;
}

.square_triangle_arrow_left_top::after {
  content: "";
  position: absolute;
  top: -50px;
  left: 16px;
  width: 0;
  height: 0;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
  border-left: 45px solid #4682b4;
  transform: rotate(45deg);
}

/* 矢印左下方向 */

.square_triangle_arrow_left_bottom {
  position: relative;
  margin-bottom: 80px;
  padding-left: 25px;
}

.square_triangle_arrow_left_bottom::after {
  content: "";
  position: absolute;
  bottom: -40px;
  left: 16px;
  width: 0;
  height: 0;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
  border-left: 45px solid #4682b4;
  transform: rotate(315deg);
}

/* 矢印右下方向 */

.square_triangle_arrow_right_bottom {
  position: relative;
  margin-bottom: 80px;
  padding-right: 25px;
}

.square_triangle_arrow_right_bottom::after {
  content: "";
  position: absolute;
  bottom: -40px;
  right: 16px;
  width: 0;
  height: 0;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
  border-left: 45px solid #4682b4;
  transform: rotate(225deg);
}
